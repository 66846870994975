import "styles/pages/thank-you.scss";

import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "components/Layout";

const ThankYou = ({ data }) => {
  const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};

  const thank_you_page_data = data.pageThankYou?.nodes[0]?.pageThankYou;

  return (
    <Layout seo={{ title: "Thank you page" }} header_setup={header_setup}>
      <div className="thank-you container-fluid">
        <h1 className="section-title">{thank_you_page_data.thankyouTitle}</h1>
        <h3>{thank_you_page_data.thankyouSubtitle}</h3>
        <div className="btn-main">
        <Link to={`${thank_you_page_data.thankyouBtnLink}`}>{thank_you_page_data.thankyouBtn}</Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    pageThankYou: allWpPage(filter: {databaseId: {eq: 622}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pageThankYou {
          thankyouBtn
          thankyouBtnLink
          thankyouSubtitle
          thankyouTitle
        }
      }
    }
  }
`

export default ThankYou;